body {
  margin: 0;
  font-family: 'Georgia', serif;
  background-color: #000000;
  color: #D3BC8D;
}

.App-header {
  background-color: #000000;
  padding: 20px;
  text-align: center;
  border-bottom: 2px solid #D3BC8D;
}

h1 {
  color: #D3BC8D;
  font-size: 3em;
}

h2 {
  color: #D3BC8D;
  font-size: 2em;
  border-bottom: 1px solid #D3BC8D;
  padding-bottom: 10px;
  margin-top: 30px;
}

p {
  font-size: 1.1em;
  line-height: 1.6;
  margin: 20px 0;
}

.photo-gallery, .biography, .instagram, .stats, .latest-news {
  padding: 20px;
  margin: 0 auto;
  max-width: 900px;
}

button {
  background-color: #D3BC8D;
  color: #000000;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1.1em;
  margin-top: 10px;
}

button a {
  color: #000000;
  text-decoration: none;
}

button:hover {
  background-color: #FFF;
}

a {
  color: #D3BC8D;
  text-decoration: none;
}

a:hover {
  color: #FFF;
}
